<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="horizontal"
      :next-button-text="$t('message.registerOrganization.next')"
      :back-button-text="$t('message.registerOrganization.previous')"
      :finish-button-text="$t('message.registerOrganization.finish')"
      class="vertical-steps steps-transparent mb-3"
      @on-complete="formSubmitted('success', 6000)"
    >
      <!-- account details tab -->

      <tab-content
        :title="$t('message.registerOrganization.informationDetails')"
        icon="feather icon-user"
        :before-change="validationFormPersonal"
      >
        <validation-observer ref="accountPersonal" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                <template>
                  {{ $t("message.registerOrganization.personalInfo") }}
                </template>
              </h5>
              <small class="text-muted">
                <template>
                  {{
                    $t("message.registerOrganization.insertYourPersonalDetails")
                  }}
                </template>
              </small>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  rules="required"
                >
                  <template v-sloat:label>
                    {{ $t("message.registerOrganization.fullName") }}
                  </template>
                  <b-form-input
                    type="text"
                    placeholder="John Doe"
                    v-model="organizationData.responsiblePerson"
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="vi-c-password">
                <validation-provider
                  #default="{ errors }"
                  name="lastName"
                  rules="required"
                >
                  <template v-sloat:label>
                    {{ $t("message.registerOrganization.jobRole") }}
                  </template>
                  <b-form-input
                    id="vi-c-password"
                    type="text"
                    :placeholder="
                      $t('message.registerOrganization.placeholderJobRole')
                    "
                    v-model="organizationData.occupation"
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- personal details tab -->
      <tab-content
        :title="$t('message.registerOrganization.companyInfo')"
        icon="feather icon-file-text"
        :before-change="validationFormCompany"
      >
        <validation-observer ref="accountCompany" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                <template>
                  {{ $t("message.registerOrganization.companyDetails") }}
                </template>
              </h5>
              <small class="text-muted">
                <template>
                  {{
                    $t(
                      "message.registerOrganization.enterYourCompanyInformation"
                    )
                  }}
                </template>
              </small>
            </b-col>
            <b-col md="12">
              <b-form-group label-for="vi-name-company">
                <validation-provider
                  #default="{ errors }"
                  name="company"
                  rules="required"
                >
                  <template v-sloat:label>
                    {{ $t("message.registerOrganization.enterCompanyInfo") }}
                  </template>
                  <b-form-input
                    id="vi-first-name"
                    :placeholder="
                      $t('message.registerOrganization.placeholderCompany')
                    "
                    v-model="organizationData.company"
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="CNPJ" label-for="vi-cnpj">
                <validation-provider
                  #default="{ errors }"
                  name="cnpj"
                  rules="required"
                >
                  <b-form-input
                    placeholder="000.000.0000.00"
                    v-mask="'##.###.###/####-##'"
                    v-model="organizationData.cnpj"
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-for="vi-email">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <template v-sloat:label>
                    {{ $t("message.registerOrganization.emailFromTheCompany") }}
                  </template>
                  <b-form-input
                    :placeholder="
                      $t('message.registerOrganization.placeholderEmailCompany')
                    "
                    v-model="organizationData.emailCompany"
                    type="email"
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-for="vi-phone">
                <validation-provider
                  #default="{ errors }"
                  name="phone"
                  rules="required"
                >
                  <template v-sloat:label>
                    {{ $t("message.registerOrganization.organizationPhone") }}
                  </template>
                  <b-form-input
                    placeholder="+55 9995959999"
                    v-model="organizationData.phone"
                    v-mask="'(+##)## #####-#####'"
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
                <small style="font-size: 10px; color: red">

                  {{ $t("message.registerOrganization.organizationPhoneInfoError") }}
                </small>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-for="vi-phone">
                <validation-provider
                  #default="{ errors }"
                  name="phone"
                  rules="required"
                >
                  <template v-sloat:label>
                    {{ $t("message.registerOrganization.organizationPhoneInfo") }}
                  </template>
                  <b-form-input
                    placeholder="+55 9995959999"
                    v-model="organizationData.cellPhone"
                    v-mask="'(+##)## #####-#####'"
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
                <small style="font-size: 10px; color: red"
                  >
                     {{ $t("message.registerOrganization.organizationPhoneInfoError2") }}
                  </small
                >
              </b-form-group>
            </b-col>            
            <!--<b-col md="12">
              <b-form-group label-for="vi-phone">
                <template v-sloat:label>
                  {{ $t("message.registerOrganization.justification") }}
                </template>

                <b-form-textarea
                  :placeholder="
                    $t('message.registerOrganization.justificationPlaceholder')
                  "
                  v-model="organizationData.moreInformation"
                  type="text"
                />
              </b-form-group>
            </b-col>-->
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- address -->
      <tab-content
        :title="$t('message.registerOrganization.address')"
        icon="feather icon-map-pin"
        :before-change="validationFormAddress"
      >
        <validation-observer ref="accountAddress" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                <template>
                  {{ $t("message.registerOrganization.address") }}
                </template>
              </h5>
              <small class="text-muted">
                <template>
                  {{
                    $t(
                      "message.registerOrganization.insertTheAddressYourCompany"
                    )
                  }}
                </template>
              </small>
            </b-col>
            <b-col md="12">
              <b-form-group label-for="vi-address">
                <validation-provider
                  #default="{ errors }"
                  name="adress"
                  rules="required"
                >
                  <template v-sloat:label>
                    {{ $t("message.registerOrganization.address") }}
                  </template>
                  <b-form-input
                    id="vi-address"
                    :placeholder="
                      $t('message.registerOrganization.placeholderAdress')
                    "
                    v-model="organizationData.adress"
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label-for="vi-address">
                <validation-provider
                  #default="{ errors }"
                  name="complement"
                  rules="required"
                >
                  <template v-sloat:label>
                    {{ $t("message.registerOrganization.complement") }}
                  </template>
                  <b-form-input
                    id="vi-complement"
                    :placeholder="
                      $t('message.registerOrganization.placeholderComplement')
                    "
                    v-model="organizationData.complement"
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-for="vi-landmark">
                <validation-provider
                  #default="{ errors }"
                  name="district"
                  rules="required"
                >
                  <template v-sloat:label>
                    {{ $t("message.registerOrganization.district") }}
                  </template>
                  <b-form-input
                    id="vi-landmark"
                    :placeholder="
                      $t('message.registerOrganization.placeholderDistrict')
                    "
                    v-model="organizationData.district"
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-for="vi-pincode">
                <validation-provider
                  #default="{ errors }"
                  name="zipCode"
                  rules="required"
                >
                  <template v-sloat:label>
                    {{ $t("message.registerOrganization.zipCode") }}
                  </template>
                  <b-form-input
                    id="vi-pincode"
                    placeholder="658921"
                    v-model="organizationData.zipCode"
                    v-mask="'#####-###'"
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-for="vi-city">
                <validation-provider
                  #default="{ errors }"
                  name="city"
                  rules="required"
                >
                  <template v-sloat:label>
                    {{ $t("message.registerOrganization.city") }}
                  </template>
                  <b-form-input
                    id="vi-city"
                    :placeholder="
                      $t('message.registerOrganization.placeholderCity')
                    "
                    v-model="organizationData.city"
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-for="vi-uf">
                <validation-provider
                  #default="{ errors }"
                  name="uf"
                  rules="required"
                >
                  <template v-sloat:label>
                    {{ $t("message.registerOrganization.uf") }}
                  </template>
                  <b-form-input
                    id="vi-language"
                    v-model="organizationData.uf"
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Documentation area -->
      <tab-content
        :title="$t('message.registerOrganization.documentation')"
        icon="feather icon-link"
        :before-change="validationFormDocumentations"
      >
        <validation-observer ref="accountDocumentation" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                <template>
                  {{
                    $t(
                      "message.registerOrganization.insertYourCompanyDocumentationHere"
                    )
                  }}
                </template>
              </h5>
              <small class="text-muted">
                <template>
                  {{ $t("message.registerOrganization.stepOfDocumentation") }}
                </template>
              </small>
            </b-col>

            <b-col md="12">
              <b-form-group label-for="vi-twitter">
                <b-form-file multiple accept=".pdf" v-model="docs">
                </b-form-file>
                <small style="font-size: 12px; color: red"
                  >Obs: Para empresas brasileiras, obrigatório anexar cartão cnpj e contrato social.</small
                >
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group>
                <validation-provider
                  name="terms"
                  rules="required"
                  #default="{ errors }"
                >
                  <b-form-checkbox
                    v-model="organizationData.termsAndConditions"
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ $t("message.registerOrganization.agree") }}
                    <b-link target="_blank" href="/TermsAndConditions">
                      {{ $t("message.welcome.termsAndConditions") }}
                    </b-link>
                  </b-form-checkbox>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Documentation area -->
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormInvalidFeedback,
  BFormCheckbox,
  BLink,
  BFormTextarea,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import Ripple from "vue-ripple-directive";
import emailLogo from "@/@core/utils/email/email-logo.js";
import emailCss from "@/@core/utils/email/email-css.js";
import { ref } from "@vue/composition-api";
import { required } from "@validations";
import getHeaderFile from "@core/utils/authFile";
import getHeader from "@core/utils/auth";

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormFile,
    BLink,
    BFormTextarea,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BFormCheckbox,
  },

  data() {
    return {
      organizationData: {},
      organizationId: "",

      filesData: [],

      docs: [],

      paramList: [],

      blockButton: false,
    };
  },

  async created() {
    await this.getParam();
  },

  methods: {
    async getParam() {
      await axios.get(`${URL_API}param-login`, {}).then((response) => {
        this.paramList = response.data;
      });
    },

    validationFormPersonal() {
      return new Promise((resolve, reject) => {
        this.$refs.accountPersonal.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    async validationFormCompany() {
      return new Promise((resolve, reject) => {
        this.$refs.accountCompany.validate().then((success) => {
          if (this.organizationData.emailCompany != undefined) {
            axios
              .get(
                `${URL_API}user-email/` + this.organizationData.emailCompany,
                {}
              )
              .then((response) => {
                if (response.data === true) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$i18n.t("Error"),
                      icon: "XIcon",
                      variant: "danger",
                      text: `Email inválido, por favor selecione outro e tente novamente`,
                    },
                  });
                  reject();
                } else {
                  axios
                    .get(
                      `${URL_API}employee-email/` +
                        this.organizationData.emailCompany,
                      {}
                    )
                    .then((response) => {
                      if (response.data === true) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: this.$i18n.t("Error"),
                            icon: "XIcon",
                            variant: "danger",
                            text: `Email inválido, por favor selecione outro e tente novamente`,
                          },
                        });
                        reject();
                      } else {
                        if (success) {
                          resolve(true);
                        } else {
                          reject();
                        }
                      }
                    });
                }
              });
          } else {
            if (success) {
              resolve(true);
            } else {
              reject();
            }
          }
        });
      });
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.accountAddress.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    validationFormDocumentations() {
      return new Promise((resolve, reject) => {
        this.$refs.accountDocumentation.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    fileErroMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$i18n.t("Error"),
          icon: "XIcon",
          variant: "danger",
          text: `O sistema apenas permite arquivos no formato .pdf`,
        },
      });
    },

    async formSubmitted() {
      this.organizationData.status = "Pendente";
      this.organizationData.moreInformation = "Nada";

      this.organizationData.phone = this.organizationData.phone.replace(
        /\D/g,
        ""
      );
      this.organizationData.cellPhone = this.organizationData.cellPhone.replace(
        /\D/g,
        ""
      );

      this.filesData = [];

      var validateDocs = [];

      if (this.docs != null) {
        for (var i = 0; i < this.docs.length; i++) {
          if (this.docs[i].type === "application/pdf") {
            validateDocs[i] = true;
            this.filesData.push(this.docs[i]);
          } else {
            validateDocs[i] = false;
          }
        }
      }

      let checker = (arr) => arr.every(Boolean);

      if (checker(validateDocs)) {
        await axios({
          method: "post",
          url: `${URL_API}organization`,
          data: this.organizationData,
        }).then((response) => {
          if (this.filesData.length > 0) {
            this.sendEmail(response.data.id);
          } else {
            this.sendEmailWithOutAttach(response.data.id);
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t("message.toast.create"),
              icon: "CheckIcon",
              variant: "success",
              text: `O seu pré cadastro foi realizado com sucesso. Aguarde o nosso email de confirmação`,
            },
          });

          this.$router.push({ name: "auth-login" });
        });
      } else {
        this.fileErroMessage();
      }
    },

    async sendEmail(organizationId) {
      const formData = new FormData();
      this.filesData.forEach((file) => {
        formData.append("files", file);
      });

      await axios
        .post(
          `${URL_API}email-organization/${organizationId}/${this.paramList.defaultUser}`,
          formData,
          {
            headers: getHeaderFile(this.userData),
          }
        )
        .then((response) => {});
    },

    async sendEmailWithOutAttach(organizationId) {
      await axios
        .post(
          `${URL_API}email-organization-non/${organizationId}/${this.paramList.defaultUser}`,
          {
            headers: getHeader(this.userData),
          }
        )
        .then((response) => {});
    },
  },
};
</script>

<style>
.col-form-label {
  padding: 0px;
}

.vue-form-wizard .wizard-tab-content {
  padding-top: 66px;
}

.vue-form-wizard.md .wizard-icon-circle {
  width: 50px;
  height: 50px;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
</style>
