var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{staticClass:"vertical-steps steps-transparent mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"layout":"horizontal","next-button-text":_vm.$t('message.registerOrganization.next'),"back-button-text":_vm.$t('message.registerOrganization.previous'),"finish-button-text":_vm.$t('message.registerOrganization.finish')},on:{"on-complete":function($event){return _vm.formSubmitted('success', 6000)}}},[_c('tab-content',{attrs:{"title":_vm.$t('message.registerOrganization.informationDetails'),"icon":"feather icon-user","before-change":_vm.validationFormPersonal}},[_c('validation-observer',{ref:"accountPersonal",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[[_vm._v(" "+_vm._s(_vm.$t("message.registerOrganization.personalInfo"))+" ")]],2),_c('small',{staticClass:"text-muted"},[[_vm._v(" "+_vm._s(_vm.$t("message.registerOrganization.insertYourPersonalDetails"))+" ")]],2)]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [[_vm._v(" "+_vm._s(_vm.$t("message.registerOrganization.fullName"))+" ")],_c('b-form-input',{attrs:{"type":"text","placeholder":"John Doe","state":errors.length > 0 ? false : null},model:{value:(_vm.organizationData.responsiblePerson),callback:function ($$v) {_vm.$set(_vm.organizationData, "responsiblePerson", $$v)},expression:"organizationData.responsiblePerson"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"vi-c-password"}},[_c('validation-provider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [[_vm._v(" "+_vm._s(_vm.$t("message.registerOrganization.jobRole"))+" ")],_c('b-form-input',{attrs:{"id":"vi-c-password","type":"text","placeholder":_vm.$t('message.registerOrganization.placeholderJobRole'),"state":errors.length > 0 ? false : null},model:{value:(_vm.organizationData.occupation),callback:function ($$v) {_vm.$set(_vm.organizationData, "occupation", $$v)},expression:"organizationData.occupation"}})]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('message.registerOrganization.companyInfo'),"icon":"feather icon-file-text","before-change":_vm.validationFormCompany}},[_c('validation-observer',{ref:"accountCompany",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[[_vm._v(" "+_vm._s(_vm.$t("message.registerOrganization.companyDetails"))+" ")]],2),_c('small',{staticClass:"text-muted"},[[_vm._v(" "+_vm._s(_vm.$t( "message.registerOrganization.enterYourCompanyInformation" ))+" ")]],2)]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"vi-name-company"}},[_c('validation-provider',{attrs:{"name":"company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [[_vm._v(" "+_vm._s(_vm.$t("message.registerOrganization.enterCompanyInfo"))+" ")],_c('b-form-input',{attrs:{"id":"vi-first-name","placeholder":_vm.$t('message.registerOrganization.placeholderCompany'),"state":errors.length > 0 ? false : null},model:{value:(_vm.organizationData.company),callback:function ($$v) {_vm.$set(_vm.organizationData, "company", $$v)},expression:"organizationData.company"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"CNPJ","label-for":"vi-cnpj"}},[_c('validation-provider',{attrs:{"name":"cnpj","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],attrs:{"placeholder":"000.000.0000.00","state":errors.length > 0 ? false : null},model:{value:(_vm.organizationData.cnpj),callback:function ($$v) {_vm.$set(_vm.organizationData, "cnpj", $$v)},expression:"organizationData.cnpj"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"vi-email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [[_vm._v(" "+_vm._s(_vm.$t("message.registerOrganization.emailFromTheCompany"))+" ")],_c('b-form-input',{attrs:{"placeholder":_vm.$t('message.registerOrganization.placeholderEmailCompany'),"type":"email","state":errors.length > 0 ? false : null},model:{value:(_vm.organizationData.emailCompany),callback:function ($$v) {_vm.$set(_vm.organizationData, "emailCompany", $$v)},expression:"organizationData.emailCompany"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"vi-phone"}},[_c('validation-provider',{attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [[_vm._v(" "+_vm._s(_vm.$t("message.registerOrganization.organizationPhone"))+" ")],_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(+##)## #####-#####'),expression:"'(+##)## #####-#####'"}],attrs:{"placeholder":"+55 9995959999","state":errors.length > 0 ? false : null},model:{value:(_vm.organizationData.phone),callback:function ($$v) {_vm.$set(_vm.organizationData, "phone", $$v)},expression:"organizationData.phone"}})]}}])}),_c('small',{staticStyle:{"font-size":"10px","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t("message.registerOrganization.organizationPhoneInfoError"))+" ")])],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"vi-phone"}},[_c('validation-provider',{attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [[_vm._v(" "+_vm._s(_vm.$t("message.registerOrganization.organizationPhoneInfo"))+" ")],_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(+##)## #####-#####'),expression:"'(+##)## #####-#####'"}],attrs:{"placeholder":"+55 9995959999","state":errors.length > 0 ? false : null},model:{value:(_vm.organizationData.cellPhone),callback:function ($$v) {_vm.$set(_vm.organizationData, "cellPhone", $$v)},expression:"organizationData.cellPhone"}})]}}])}),_c('small',{staticStyle:{"font-size":"10px","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t("message.registerOrganization.organizationPhoneInfoError2"))+" ")])],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('message.registerOrganization.address'),"icon":"feather icon-map-pin","before-change":_vm.validationFormAddress}},[_c('validation-observer',{ref:"accountAddress",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[[_vm._v(" "+_vm._s(_vm.$t("message.registerOrganization.address"))+" ")]],2),_c('small',{staticClass:"text-muted"},[[_vm._v(" "+_vm._s(_vm.$t( "message.registerOrganization.insertTheAddressYourCompany" ))+" ")]],2)]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"vi-address"}},[_c('validation-provider',{attrs:{"name":"adress","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [[_vm._v(" "+_vm._s(_vm.$t("message.registerOrganization.address"))+" ")],_c('b-form-input',{attrs:{"id":"vi-address","placeholder":_vm.$t('message.registerOrganization.placeholderAdress'),"state":errors.length > 0 ? false : null},model:{value:(_vm.organizationData.adress),callback:function ($$v) {_vm.$set(_vm.organizationData, "adress", $$v)},expression:"organizationData.adress"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"vi-address"}},[_c('validation-provider',{attrs:{"name":"complement","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [[_vm._v(" "+_vm._s(_vm.$t("message.registerOrganization.complement"))+" ")],_c('b-form-input',{attrs:{"id":"vi-complement","placeholder":_vm.$t('message.registerOrganization.placeholderComplement'),"state":errors.length > 0 ? false : null},model:{value:(_vm.organizationData.complement),callback:function ($$v) {_vm.$set(_vm.organizationData, "complement", $$v)},expression:"organizationData.complement"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"vi-landmark"}},[_c('validation-provider',{attrs:{"name":"district","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [[_vm._v(" "+_vm._s(_vm.$t("message.registerOrganization.district"))+" ")],_c('b-form-input',{attrs:{"id":"vi-landmark","placeholder":_vm.$t('message.registerOrganization.placeholderDistrict'),"state":errors.length > 0 ? false : null},model:{value:(_vm.organizationData.district),callback:function ($$v) {_vm.$set(_vm.organizationData, "district", $$v)},expression:"organizationData.district"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"vi-pincode"}},[_c('validation-provider',{attrs:{"name":"zipCode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [[_vm._v(" "+_vm._s(_vm.$t("message.registerOrganization.zipCode"))+" ")],_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#####-###'),expression:"'#####-###'"}],attrs:{"id":"vi-pincode","placeholder":"658921","state":errors.length > 0 ? false : null},model:{value:(_vm.organizationData.zipCode),callback:function ($$v) {_vm.$set(_vm.organizationData, "zipCode", $$v)},expression:"organizationData.zipCode"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"vi-city"}},[_c('validation-provider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [[_vm._v(" "+_vm._s(_vm.$t("message.registerOrganization.city"))+" ")],_c('b-form-input',{attrs:{"id":"vi-city","placeholder":_vm.$t('message.registerOrganization.placeholderCity'),"state":errors.length > 0 ? false : null},model:{value:(_vm.organizationData.city),callback:function ($$v) {_vm.$set(_vm.organizationData, "city", $$v)},expression:"organizationData.city"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"vi-uf"}},[_c('validation-provider',{attrs:{"name":"uf","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [[_vm._v(" "+_vm._s(_vm.$t("message.registerOrganization.uf"))+" ")],_c('b-form-input',{attrs:{"id":"vi-language","state":errors.length > 0 ? false : null},model:{value:(_vm.organizationData.uf),callback:function ($$v) {_vm.$set(_vm.organizationData, "uf", $$v)},expression:"organizationData.uf"}})]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('message.registerOrganization.documentation'),"icon":"feather icon-link","before-change":_vm.validationFormDocumentations}},[_c('validation-observer',{ref:"accountDocumentation",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[[_vm._v(" "+_vm._s(_vm.$t( "message.registerOrganization.insertYourCompanyDocumentationHere" ))+" ")]],2),_c('small',{staticClass:"text-muted"},[[_vm._v(" "+_vm._s(_vm.$t("message.registerOrganization.stepOfDocumentation"))+" ")]],2)]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"vi-twitter"}},[_c('b-form-file',{attrs:{"multiple":"","accept":".pdf"},model:{value:(_vm.docs),callback:function ($$v) {_vm.docs=$$v},expression:"docs"}}),_c('small',{staticStyle:{"font-size":"12px","color":"red"}},[_vm._v("Obs: Para empresas brasileiras, obrigatório anexar cartão cnpj e contrato social.")])],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"terms","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.organizationData.termsAndConditions),callback:function ($$v) {_vm.$set(_vm.organizationData, "termsAndConditions", $$v)},expression:"organizationData.termsAndConditions"}},[_vm._v(" "+_vm._s(_vm.$t("message.registerOrganization.agree"))+" "),_c('b-link',{attrs:{"target":"_blank","href":"/TermsAndConditions"}},[_vm._v(" "+_vm._s(_vm.$t("message.welcome.termsAndConditions"))+" ")])],1)]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }